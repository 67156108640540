import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Modal,
  TextField,
  IconButton,
  Badge,
  Collapse,
  Tooltip, // Import Collapse
} from "@mui/material";
import { format } from "date-fns";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  addComment,
  fetchStructuredLogs,
} from "../../../redux/actions/conversationsActions";
import ThreadComponent from "./ThreadComponent";
import { Message, Comment } from "../../../redux/reducers/conversationsReducer";
import { getTargetId } from "../../../utils/render";
import CloseIcon from "@mui/icons-material/Close";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ShareIcon from "@mui/icons-material/Share";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ImageIcon from "@mui/icons-material/Image";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import MovieIcon from "@mui/icons-material/Movie"; // For audio_video
import InfoIcon from "@mui/icons-material/Info";
import StructuredLogsViewer from "./StructuredLogsViewer";

// TypeScript Enums
export enum ChatAnnotationCategory {
  Unknown = 0,
  Reaction = 1,
  Feedback = 2,
  Violation = 3,
}

export enum ChatAnnotationReaction {
  Unknown = 0,
  Love = 1,
  Like = 2,
  Cry = 3,
  Think = 4,
  Lol = 5,
  Question = 6,
  Rage = 7,
}

export enum ChatAnnotationFeedback {
  Unknown = 0,
  ThumbsUp = 1,
  ThumbsDown = 2,
}

export enum ChatMessageModality {
  Text = "text",
  Audio = "audio",
  Video = "video",
  AudioVideo = "audio_video",
}

// TypeScript Interface
export interface ChatAnnotation {
  category: ChatAnnotationCategory;
  content: ChatAnnotationReaction | ChatAnnotationFeedback;
  chat_speaker_id: string;
  action: any | null;
  speaker_num: number | null;
  speaker_type: any | null;
  timestamp: string;
}

interface MessageBubbleProps {
  message: Message;
  chatSessionId: string;
  isRightAligned: boolean;
  conversationId?: string;
  canViewAllData?: boolean;
  comments?: Comment[];
  onHeightChange?: (height: number) => void;
  isHighlighted?: boolean;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  isRightAligned,
  conversationId,
  canViewAllData,
  comments = [],
  chatSessionId,
  onHeightChange,
  isHighlighted,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [showComments, setShowComments] = useState(true); // State for toggling comments
  const [showShareTooltip, setShowShareTooltip] = useState(false);

  const [showLogsModal, setShowLogsModal] = useState(false);

  const messageId = message.id ?? message.chat_message_id;
  const chatSessionIdFromMessage = message.chat_session_id ?? chatSessionId;

  const targetId = getTargetId(messageId, chatSessionIdFromMessage);

  const structuredLogs = useSelector(
    (state: RootState) => state.conversations.structuredLogs[targetId]
  );
  const loadingStructuredLogs = useSelector(
    (state: RootState) => state.conversations.loadingStructuredLogs[targetId]
  );

  const { user: adminUser } = useSelector((state: RootState) => state.base);

  const canViewModelCalls = adminUser?.scopes?.includes("structuredlog:read");

  const handleShowLogsModal = () => {
    setShowLogsModal(true);
    if (!structuredLogs && !loadingStructuredLogs && message.client_msg_uid) {
      dispatch(
        fetchStructuredLogs({
          conversationId: conversationId || "",
          chatSessionId: chatSessionIdFromMessage,
          clientMsgUid: message.client_msg_uid,
          startMsgId: messageId,
          requestedEvents: [
            {
              event: "llm_inference_event",
              orderings: ["CREATED_AT_DESC"],
            },
            {
              event: "model_inference_event",
              orderings: ["CREATED_AT_DESC"],
            },
          ],
        })
      );
    }
  };

  const fetchImage = async () => {
    setLoadingImage(true);
    try {
      const encryptedJwt = localStorage.getItem("jwt");
      let jwt = null;

      if (encryptedJwt) {
        const bytes = CryptoJS.AES.decrypt(
          encryptedJwt,
          process.env.REACT_APP_CRYPTO_SECRET_KEY ?? ""
        );
        jwt = bytes.toString(CryptoJS.enc.Utf8);
      }

      const headers: HeadersInit = {
        "Content-Type": "image/jpeg",
      };

      if (jwt) {
        headers["Authorization"] = `Bearer ${jwt}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_MEDIA_SERVER_URL}v1/media/download_media?conversation_id=${conversationId}&blob_path=${message.media_ref?.blob_path}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download image");
      }

      const arrayBuffer = await response.arrayBuffer();

      // Convert ArrayBuffer to base64 string
      const base64String = btoa(
        new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      setImageUrl(
        `data:${message.media_ref?.content_type};base64,${base64String}`
      );
    } catch (error) {
      console.error("Error fetching image:", error);
    } finally {
      setLoadingImage(false);
    }
  };

  const handleShareMessage = () => {
    navigator.clipboard.writeText(
      `${
        process.env.REACT_APP_LOGIN_REDIRECT_URL
      }/conversations/${conversationId}?target_id=${getTargetId(
        message.id ?? message.chat_message_id,
        chatSessionId
      )}`
    );
    setShowShareTooltip(true);
    setTimeout(() => setShowShareTooltip(false), 2000);
  };

  const bubbleRef = useRef<HTMLDivElement>(null);

  // Call onHeightChange when content changes to update the height
  useEffect(() => {
    if (bubbleRef.current) {
      const height = bubbleRef.current.getBoundingClientRect().height;
      if (onHeightChange) {
        onHeightChange(height);
      }
    }
  }, [
    onHeightChange,
    imageUrl,
    showComments,
    commentText,
    message.content,
    loadingImage,
    comments.length,
    // Include any other state or props that affect content height
  ]);

  const handleAddComment = () => {
    if (
      conversationId &&
      (message?.id != null || message.chat_message_id != null) &&
      commentText.trim() !== ""
    ) {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: getTargetId(
            message.id ?? message.chat_message_id,
            chatSessionId
          ),
          comment: commentText,
        })
      );
      setCommentText("");
      setShowCommentModal(false);
      setShowComments(true); // Ensure comments are shown after adding a new comment
    }
  };

  const renderModalityIcon = (modality: ChatMessageModality) => {
    switch (modality) {
      case ChatMessageModality.Text:
        return <TextFieldsIcon />;
      case ChatMessageModality.Audio:
        return <MicIcon />;
      case ChatMessageModality.Video:
        return <VideocamIcon />;
      case ChatMessageModality.AudioVideo:
        return <MovieIcon />;
    }
  };

  const renderAnnotationIcon = (annotation: ChatAnnotation) => {
    const { category, content } = annotation;
    switch (category) {
      case ChatAnnotationCategory.Reaction:
        switch (content) {
          case ChatAnnotationReaction.Love:
            return (
              <span role="img" aria-label="Love">
                ❤️
              </span>
            );
          case ChatAnnotationReaction.Like:
            return (
              <span role="img" aria-label="Like">
                👍
              </span>
            );
          case ChatAnnotationReaction.Cry:
            return (
              <span role="img" aria-label="Cry">
                😢
              </span>
            );
          case ChatAnnotationReaction.Think:
            return (
              <span role="img" aria-label="Think">
                🤔
              </span>
            );
          case ChatAnnotationReaction.Lol:
            return (
              <span role="img" aria-label="Lol">
                😂
              </span>
            );
          case ChatAnnotationReaction.Question:
            return (
              <span role="img" aria-label="Question">
                ❓
              </span>
            );
          case ChatAnnotationReaction.Rage:
            return (
              <span role="img" aria-label="Rage">
                😡
              </span>
            );
          case ChatAnnotationReaction.Unknown:
          default:
            return (
              <span role="img" aria-label="Unknown">
                ❔
              </span>
            );
        }
      case ChatAnnotationCategory.Feedback:
        switch (content) {
          case ChatAnnotationFeedback.ThumbsUp:
            return (
              <span role="img" aria-label="Thumbs Up">
                👍
              </span>
            );
          case ChatAnnotationFeedback.ThumbsDown:
            return (
              <span role="img" aria-label="Thumbs Down">
                👎
              </span>
            );
          case ChatAnnotationFeedback.Unknown:
          default:
            return (
              <span role="img" aria-label="Unknown">
                ❔
              </span>
            );
        }
      case ChatAnnotationCategory.Violation:
        return (
          <span role="img" aria-label="Violation">
            ⚠️
          </span>
        );
      case ChatAnnotationCategory.Unknown:
      default:
        return (
          <span role="img" aria-label="Unknown">
            ❔
          </span>
        );
    }
  };

  const bubbleStyles = {
    backgroundColor: isRightAligned ? "#DCF8C6" : "#F0F0F0",
    color: isRightAligned ? "black" : "black",
    padding: "12px",
    borderRadius: "12px",
    maxWidth: "100%",
    marginBottom: "8px",
    alignSelf: isRightAligned ? "flex-end" : "flex-start",
    transition: "background-color 2s ease",
  };

  const formattedDate = format(
    new Date(message.created_at),
    "MMM d, yyyy HH:mm"
  );

  // Group comments into threads by thread_id
  const threadsMap = comments.reduce((threads, comment) => {
    if (!threads[comment.thread_id]) {
      threads[comment.thread_id] = [];
    }
    threads[comment.thread_id].push(comment);
    return threads;
  }, {} as Record<string, Comment[]>);

  // Convert threadsMap to an array and sort threads by latest comment date descending
  const threads = Object.values(threadsMap).sort((a, b) => {
    const latestA = new Date(
      a.reduce(
        (max, comment) =>
          new Date(comment.created_at) > new Date(max)
            ? comment.created_at
            : max,
        a[0].created_at
      )
    );
    const latestB = new Date(
      b.reduce(
        (max, comment) =>
          new Date(comment.created_at) > new Date(max)
            ? comment.created_at
            : max,
        b[0].created_at
      )
    );
    return latestB.getTime() - latestA.getTime();
  });

  return (
    <Box
      ref={bubbleRef}
      sx={{ display: "flex", alignItems: "flex-start" }}
      id={`${getTargetId(
        message.id ?? message.chat_message_id,
        chatSessionId
      )}`}
    >
      {/* Message Bubble */}
      <div style={{ position: "relative", flex: 1 }}>
        <Box
          sx={bubbleStyles}
          className={isHighlighted ? "bubble-highlight" : "bubble-normal"}
        >
          {message.content && (
            <Typography
              variant="body1"
              textAlign={isRightAligned ? "right" : "left"}
            >
              {message.content}
            </Typography>
          )}
          {message.annotations &&
            message.annotations.length > 0 &&
            message.annotations.map((annotation, index) => (
              <span key={index}>{renderAnnotationIcon(annotation)}</span>
            ))}
          {loadingImage && <CircularProgress size={24} />}
          {!imageUrl && message.media_ref && message.media_ref.blob_path && (
            <>
              {canViewAllData ? (
                <Button variant="outlined" onClick={fetchImage}>
                  Download Image
                </Button>
              ) : (
                <Typography variant="body1">
                  {"<Hidden Image that was uploaded by client>"}
                </Typography>
              )}
            </>
          )}
          {imageUrl && (
            <img src={imageUrl} alt="Media" style={{ maxWidth: "100%" }} />
          )}
          <Typography
            color="textSecondary"
            textAlign={isRightAligned ? "right" : "left"}
            fontSize={10}
          >
            {formattedDate}
          </Typography>

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 1,
            }}
          >
            {message.modality &&
            message.modality !== ChatMessageModality.Text ? (
              <Box sx={{ marginRight: "auto" }}>
                {" "}
                {renderModalityIcon(message.modality)}{" "}
              </Box>
            ) : null}
            {message.content_type === "image_with_description" ? (
              <Box sx={{ marginRight: "auto" }}>
                <ImageIcon />
              </Box>
            ) : null}

            {/* Pen Icon Button - Opens Add Comment Modal */}
            <IconButton
              size="small"
              onClick={() => setShowCommentModal(true)}
              aria-label="Add Comment"
            >
              <AddCommentIcon style={{ color: "black" }} />
            </IconButton>
            {message.type === "human" && canViewModelCalls ? (
              <IconButton
                size="small"
                onClick={handleShowLogsModal}
                aria-label="Show Logs"
              >
                <InfoIcon style={{ color: "black" }} />
              </IconButton>
            ) : null}

            {/* Chat Bubble Icon Button - Toggles Comments */}
            {comments.length > 0 && (
              <IconButton
                size="small"
                onClick={() => setShowComments((prev) => !prev)}
                aria-label="Toggle Comments"
              >
                <Badge
                  badgeContent={comments.length}
                  color="info"
                  overlap="circular"
                >
                  <QuestionAnswerIcon style={{ color: "black" }} />
                </Badge>
              </IconButton>
            )}
            <Tooltip
              title={showShareTooltip ? "Copied to clipboard" : "Share message"}
              open={showShareTooltip}
              onClose={() => setShowShareTooltip(false)}
            >
              <IconButton size="small" onClick={handleShareMessage}>
                <ShareIcon style={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Comments with Slide Transition */}
        {threads.length > 0 && (
          <Collapse in={showComments} timeout="auto" unmountOnExit>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                zIndex: "20",
              }}
            >
              {threads.map((threadComments) => (
                <ThreadComponent
                  key={threadComments[0].thread_id}
                  comments={threadComments}
                  conversationId={conversationId || ""}
                />
              ))}
            </Box>
          </Collapse>
        )}

        {/* Structured Logs Modal*/}
        {/* Logs Modal */}
        {canViewModelCalls ? (
          <Modal open={showLogsModal} onClose={() => setShowLogsModal(false)}>
            <Box
              sx={{
                position: "absolute" as const,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600,
                maxHeight: "80vh",
                overflowY: "auto",
                bgcolor: "background.paper",
                borderRadius: 1,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h4" component="h4">
                  Message Events
                </Typography>
                <IconButton
                  style={{ marginTop: "-4px" }}
                  onClick={() => setShowLogsModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {loadingStructuredLogs ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                    animation: "bounce 1.5s infinite",
                  }}
                >
                  <CircularProgress />
                  <Box sx={{ mt: 1 }}>Loading this message's events...</Box>
                </Box>
              ) : structuredLogs ? (
                <StructuredLogsViewer logs={structuredLogs} />
              ) : (
                <Typography variant="body1">No logs available.</Typography>
              )}
            </Box>
          </Modal>
        ) : null}

        {/* Comment Modal */}
        <Modal
          open={showCommentModal}
          onClose={() => setShowCommentModal(false)}
        >
          <Box
            sx={{
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" component="h2">
                Add Comment
              </Typography>
              <IconButton
                style={{ marginTop: "-4px" }}
                onClick={() => setShowCommentModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={handleAddComment}
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};

export default MessageBubble;
