import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { RootState } from "../../../redux/store";
import {
  SafetyIncident,
} from "../../../redux/reducers/safetyIncidentsReducer";

const SafetyIncidentView: React.FC = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const navigate = useNavigate();
  const incidents = useSelector(
    (state: RootState) => state.safetyIncidents.incidents
  );
  const incident = incidents.find(
    (incident: SafetyIncident) => incident.id === incidentId
  );
  useEffect(() => {
    if (incidents.length === 0 || !incident) {
      if (incidentId) {
        navigate(`/dashboard/safety-incidents?flag_id=${incidentId}`);
      } else {
        navigate(`/dashboard/safety-incidents`);
      }
    }
  }, [incidents, incidentId, incident, navigate]);

  const handleZendeskLink = () => {
    if (incident?.zendesk_ticket_id) {
      const zendeskUrl = `https://personaai.zendesk.com/agent/tickets/${incident.zendesk_ticket_id}`;
      window.open(zendeskUrl, "_blank");
    }
  };

  if (!incident) {
    return <div>Incident not found</div>;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h5" gutterBottom>
        Incident Details
      </Typography>
      <TextField
        label="Incident ID"
        value={incident.id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Conversation ID"
        value={incident.conversation_id}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Infringement Type"
        value={incident.infringement_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Infringement Subtype"
        value={incident.infringement_sub_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Severity"
        value={incident.infringement_class}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Status"
        value={incident.review_status}
        fullWidth
        margin="normal"
        InputProps={{ readOnly: true }}
        InputLabelProps={{ shrink: true }}
      />
      {incident.zendesk_ticket_id && (
        <Button
          variant="contained"
          color="error"
          onClick={handleZendeskLink}
          style={{ marginLeft: "8px" }}
        >
          View Zendesk Ticket
        </Button>
      )}
      <Button
        variant="contained"
        color="warning"
        onClick={() => navigate("/dashboard/safety-incidents")}
        style={{ marginLeft: "8px" }}
      >
        Back
      </Button>
    </Box>
  );
};

export default SafetyIncidentView;
