// StructuredLogsViewer.tsx

import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface StructuredLogsViewerProps {
  logs: {
    event_logs: any;
  };
}

const StructuredLogsViewer: React.FC<StructuredLogsViewerProps> = ({
  logs,
}) => {
  if (!logs || !logs.event_logs) {
    return <Typography>No logs available.</Typography>;
  }

  const eventNames = Object.keys(logs.event_logs);
  // remove duplicated inference ids unless they are is_shadow
  const filterLogs = (events: any[]) => {
    const uniqueEvents = new Map();

    events.forEach((event) => {
      const inferenceId =
        event.llm_inference_event?.inference_id ||
        event.model_inference_event?.inference_id;

      if (inferenceId) {
        if (
          !uniqueEvents.has(inferenceId) ||
          event.llm_inference_event?.is_shadow
        ) {
          uniqueEvents.set(inferenceId, event);
        }
      } else {
        uniqueEvents.set(Math.random(), event); // For events without inference ID
      }
    });

    return Array.from(uniqueEvents.values());
  };

  return (
    <Box>
      {eventNames.map((eventName, idx) => (
        <Box key={eventName} sx={{ mb: 4 }}>
          {/* Divider between sections (except the first one) */}
          {idx > 0 && <Divider sx={{ mb: 2 }} />}

          {/* Section Title */}
          <Typography variant="h5" sx={{ textTransform: "capitalize", mb: 2 }}>
            {eventName.replace(/_/g, " ")}s {/* Make the title plural */}
          </Typography>

          {filterLogs(logs.event_logs[eventName]).map(
            (event: any, index: number) => {
              // Extract key information based on event type
              let title = `Event ID: ${event.meta?.id || "N/A"}`;
              let subtitle = "";

              if (
                event.context?.actor?.actor_class === "Watcher" &&
                event.context?.watcher?.name
              ) {
                subtitle += `Watcher: ${event.context?.watcher.name}`;
              }

              let summaryContent = null;
              let detailsContent = null;

              if (eventName === "llm_inference_event") {
                const llmEvent = event.llm_inference_event;
                title = `Model: ${llmEvent?.model_name || "Unknown"}`;

                summaryContent = (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {subtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                );

                detailsContent = (
                  <Box>
                    {llmEvent?.chat_prompt_messages && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                          Prompt Messages:
                        </Typography>
                        {llmEvent.chat_prompt_messages.map(
                          (message: any, idx: number) => (
                            <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                              <Chip
                                label={message.role}
                                size="small"
                                sx={{ mr: 1 }}
                              />
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                }}
                              >
                                {message.content}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    )}
                    {llmEvent?.output && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Output:</Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {llmEvent.output}
                        </Typography>
                      </Box>
                    )}
                    {llmEvent?.model_key && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Model Key:</Typography>
                        <Typography variant="body2">
                          {llmEvent.model_key}
                        </Typography>
                      </Box>
                    )}
                    {llmEvent?.model_provider && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                          Model Provider:
                        </Typography>
                        <Typography variant="body2">
                          {llmEvent.model_provider}
                        </Typography>
                      </Box>
                    )}
                    {llmEvent?.temperature !== undefined && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">
                          Temperature:
                        </Typography>
                        <Typography variant="body2">
                          {llmEvent.temperature}
                        </Typography>
                      </Box>
                    )}

                    {/* Additional Details Accordion */}
                    <Accordion sx={{ mt: 2 }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle2">
                          More Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {JSON.stringify(event, null, 2)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              } else if (eventName === "model_inference_event") {
                const modelEvent = event.model_inference_event;
                title = `Model Key: ${modelEvent?.model_key || "Unknown"}`;

                summaryContent = (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        {subtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                );

                detailsContent = (
                  <Box>
                    {modelEvent?.model_id && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Model ID:</Typography>
                        <Typography variant="body2">
                          {modelEvent.model_id}
                        </Typography>
                      </Box>
                    )}

                    {modelEvent?.inputs && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Inputs:</Typography>
                        {modelEvent.inputs.repeated_query?.queries?.map(
                          (query: string, idx: number) => (
                            <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: "pre-wrap",
                                  wordBreak: "break-word",
                                }}
                              >
                                {query}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Box>
                    )}

                    {modelEvent?.outputs && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Outputs:</Typography>
                        {modelEvent.outputs.outputs?.map(
                          (output: any, idx: number) => {
                            let outputJson = {};
                            if (output.json?.json) {
                              try {
                                outputJson = JSON.parse(output.json.json);
                              } catch (e) {
                                console.error("Error parsing output JSON:", e);
                              }
                            }
                            return (
                              <Box key={idx} sx={{ pl: 2, mb: 1 }}>
                                {Object.entries(outputJson).map(
                                  ([key, value]) => (
                                    <Typography
                                      key={key}
                                      variant="body2"
                                      sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {`${key}: ${value}`}
                                    </Typography>
                                  )
                                )}
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    )}

                    {/* Additional Details Accordion */}
                    <Accordion sx={{ mt: 2 }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle2">
                          More Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {JSON.stringify(event, null, 2)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              } else {
                // Handle other event types if necessary
                summaryContent = (
                  <Typography variant="subtitle1">{title}</Typography>
                );
                detailsContent = (
                  <Box>
                    <Typography variant="body2">
                      No specific display logic for this event type.
                    </Typography>
                    {/* Additional Details Accordion */}
                    <Accordion sx={{ mt: 2 }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle2">
                          More Details
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          variant="body2"
                          sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {JSON.stringify(event, null, 2)}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              }

              return (
                <Accordion key={index} sx={{ mb: 2 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {summaryContent}
                  </AccordionSummary>
                  <AccordionDetails>{detailsContent}</AccordionDetails>
                </Accordion>
              );
            }
          )}
        </Box>
      ))}
    </Box>
  );
};

export default StructuredLogsViewer;
