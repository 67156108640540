import React, { useEffect, useState, useRef, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Fab,
  Collapse,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { format } from "date-fns";
import {
  fetchAnonymizedChatSessions,
  fetchChatSessions,
  fetchComments,
  setConversationShadowBanned,
} from "../../../redux/actions/conversationsActions";
import MessageBubble from "./MessageBubble";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Message } from "../../../redux/reducers/conversationsReducer";
import { getFullNameFromUserName, getTargetId } from "../../../utils/render";
import { debounce } from "lodash";
import ViewOverallFeedback from "./feedback/ViewOverallFeedback";
import AddOverallFeedback from "./feedback/AddOverallFeedback";
import {
  VariableSizeList as WindowList,
  ListChildComponentProps,
} from "react-window";

interface ConversationsViewProps {
  canReadConversation?: boolean;
}

interface ItemData {
  listItems: any[];
  getCommentsForMessage: (targetId: string) => any[];
  conversationId: string | undefined;
  showRealData: boolean;
  canReadConversation?: boolean;
  itemHeights: React.MutableRefObject<Record<number, number>>;
  listRef: React.RefObject<any>;
  highlightedItemId: string | null;
}

const ConversationView: React.FC<ConversationsViewProps> = ({
  canReadConversation,
}) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [showRealData, setShowRealData] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false);
  const [highlightedItemId, setHighlightedItemId] = useState<string | null>(
    null
  );
  const highlightTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(true);

  const [dates, setDates] = useState<string[]>([]);

  const [commenters, setCommenters] = useState<
    { id: string; full_name: string }[]
  >([]);
  const [hiddenCommenters, setHiddenCommenters] = useState<Set<string>>(
    new Set()
  );

  const highlightItem = (itemId: string) => {
    setHighlightedItemId(itemId);

    // Clear any existing timeout
    if (highlightTimeoutRef.current) {
      clearTimeout(highlightTimeoutRef.current);
    }
    // Remove highlight after 2 seconds
    highlightTimeoutRef.current = setTimeout(() => {
      setHighlightedItemId(null);
    }, 2000);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetId = searchParams.get("target_id"); // Get the target_id from the URL

  const handleShadowBan = (newValue: boolean) => {
    if (!conversationId) return;

    dispatch(
      setConversationShadowBanned({
        conversationId,
        newValue,
      })
    )
      .then(() => {
        toast.success(
          `Successfully updated shadow_banned status to ${newValue}`
        );
      })
      .catch((error) => {
        toast.error("Failed to update shadow banned value");
      });
  };

  const conversation = useSelector((state: RootState) =>
    state.conversations?.conversations?.find((c) => c.id === conversationId)
  );

  const transcript = useSelector((state: RootState) =>
    state.conversations?.transcripts?.find(
      (c) => c.conversation_id === conversationId
    )
  );

  const { user: adminUser } = useSelector((state: RootState) => state.base);

  const canViewUser = adminUser?.scopes?.includes("userview:view");

  const comments = useSelector(
    (state: RootState) => state.conversations.comments
  );

  useEffect(() => {
    const uniqueCommenterMap = new Map<
      string,
      { id: string; full_name: string }
    >();
    comments.forEach((comment) => {
      if (!uniqueCommenterMap.has(comment.user.id)) {
        uniqueCommenterMap.set(comment.user.id, {
          id: comment.user.id,
          full_name: getFullNameFromUserName(comment.user.name) || "Unknown", // Adjust based on your data structure
        });
      }
    });
    const uniqueCommenters = Array.from(uniqueCommenterMap.values());
    setCommenters(uniqueCommenters);
  }, [comments]);

  const getCommentsForMessage = (targetId: string) => {
    return comments
      .filter(
        (comment) => comment.target_id === targetId && !comment.deleted_at
      )
      .filter((comment) => !hiddenCommenters.has(comment.user.id));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const targetId = queryParams.get("target_id"); // Extract target_id

    // Check if conversation exists
    if (!conversation && canReadConversation) {
      if (conversationId) {
        // Include target_id in the navigation if it exists
        const url = targetId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${targetId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }

    if (!transcript && !canReadConversation) {
      if (conversationId) {
        // Include target_id in the navigation if it exists
        const url = targetId
          ? `/dashboard/conversations?conversation_id=${conversationId}&target_id=${targetId}`
          : `/dashboard/conversations?conversation_id=${conversationId}`;
        navigate(url);
      } else {
        navigate("/dashboard/conversations");
      }
    }
  }, [
    conversation,
    conversationId,
    navigate,
    transcript,
    canReadConversation,
    location.search,
  ]);

  const debouncedFetchComments = useMemo(
    () =>
      debounce((id: string) => {
        dispatch(fetchComments(id));
      }, 300),
    [dispatch]
  );

  const memoizedConversationId = useMemo(
    () => conversationId,
    [conversationId]
  );

  // Memoize debounced functions
  const debouncedFetchChatSessions = useMemo(
    () => debounce((id: string) => dispatch(fetchChatSessions(id)), 300),
    [dispatch]
  );

  const debouncedFetchAnonymizedChatSessions = useMemo(
    () =>
      debounce((id: string) => dispatch(fetchAnonymizedChatSessions(id)), 300),
    [dispatch]
  );

  useEffect(() => {
    if (memoizedConversationId) {
      debouncedFetchComments(memoizedConversationId);
    }
    return () => debouncedFetchComments.cancel(); // Clean up on unmount
  }, [memoizedConversationId, debouncedFetchComments]);

  useEffect(() => {
    if (
      conversation?.id &&
      conversation.is_private === false &&
      canReadConversation
    ) {
      debouncedFetchChatSessions(conversation.id);
    }
    if (transcript?.conversation_id && transcript?.is_private === false) {
      debouncedFetchAnonymizedChatSessions(transcript.conversation_id);
    } else if (conversation?.id && conversation?.is_private === false) {
      debouncedFetchAnonymizedChatSessions(conversation.id);
    }

    // Cleanup to cancel debounce on unmount
    return () => {
      debouncedFetchChatSessions.cancel();
      debouncedFetchAnonymizedChatSessions.cancel();
    };
  }, [
    transcript,
    conversation,
    dispatch,
    canReadConversation,
    debouncedFetchAnonymizedChatSessions,
    debouncedFetchChatSessions,
  ]);

  const anonymizedChatSessions = useSelector(
    (state: RootState) => state.conversations.anonymizedChatSessions
  );

  const chatSessions = useSelector(
    (state: RootState) => state.conversations.chatSessions
  );

  const loading = useSelector(
    (state: RootState) => state.conversations?.loading
  );
  const loadingConversation = useSelector(
    (state: RootState) => state.conversations?.loadingConversation
  );

  const loadingTranscript = useSelector(
    (state: RootState) => state.conversations?.loadingTranscript
  );

  const loadingComments = useSelector(
    (state: RootState) => state.conversations.loadingComments
  );

  const [listItems, setListItems] = useState<any[]>([]);
  const [idToIndex, setIdToIndex] = useState<Record<string, number>>({});
  const [dateToIndex, setDateToIndex] = useState<Record<string, number>>({});
  const itemHeights = useRef<Record<number, number>>({});

  useEffect(() => {
    // Organize messages and build listItems
    if (conversationId) {
      const anonAllMessages: Message[] = anonymizedChatSessions.flatMap(
        (session) =>
          session.messages.map((message) => ({
            ...message,
            chat_session_id: session.id,
          }))
      );

      const allMessagesRaw: Message[] = chatSessions.flatMap((session) =>
        session.messages.map((message) => ({
          ...message,
          chat_session_id: session.id,
        }))
      );

      const sortedMessages = allMessagesRaw.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );
      const sortedAnonMessages = anonAllMessages.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      const messagesToUse =
        canReadConversation && showRealData
          ? sortedMessages
          : sortedAnonMessages;

      const newListItems: any[] = [];
      const newIdToIndex: Record<string, number> = {};
      const newDateToIndex: Record<string, number> = {};

      let previousDate = "";
      let previousSessionId = "";
      messagesToUse.forEach((message) => {
        const messageDate = format(
          new Date(message.created_at),
          "MMMM dd, yyyy"
        );
        if (messageDate !== previousDate) {
          newListItems.push({ type: "date", date: messageDate });
          newDateToIndex[messageDate] = newListItems.length - 1;
          previousDate = messageDate;
        }
        if (message.chat_session_id !== previousSessionId) {
          newListItems.push({
            type: "session",
            sessionId: message.chat_session_id,
          });
          previousSessionId = message.chat_session_id;
        }
        const targetId = getTargetId(
          showRealData && canReadConversation
            ? message.id
            : message.chat_message_id,
          message.chat_session_id
        );
        newListItems.push({ type: "message", message, targetId });

        // Map targetId to index in listItems
        newIdToIndex[targetId] = newListItems.length - 1;
      });
      setListItems(newListItems);
      setIdToIndex(newIdToIndex);
      setDateToIndex(newDateToIndex);

      // Extract dates for date selector
      setDates(Object.keys(newDateToIndex));
    }
  }, [
    chatSessions,
    conversationId,
    anonymizedChatSessions,
    canReadConversation,
    showRealData,
  ]);

  const handleViewUser = (userId: string | null) => {
    if (userId) {
      navigate(`/dashboard/users?user=${userId}`);
    }
  };

  const listRef = useRef<any>();

  const scrollToDate = (date: string) => {
    if (dateToIndex[date] !== undefined && listRef.current) {
      listRef.current.scrollToItem(dateToIndex[date], "start");
      setSelectedDate(date);
      highlightItem(date);
    }
  };

  // Scroll to specific message by targetId
  useEffect(() => {
    if (targetId && idToIndex[targetId] !== undefined && listRef.current) {
      listRef.current.scrollToItem(idToIndex[targetId], "start");
      highlightItem(targetId);
    }
  }, [targetId, idToIndex]);

  useEffect(() => {
    return () => {
      if (highlightTimeoutRef.current) {
        clearTimeout(highlightTimeoutRef.current);
      }
    };
  }, []);

  const handleScroll = (props: any) => {
    if (listRef.current) {
      const { scrollOffset } = listRef.current.state;
      if (scrollOffset > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }

      // Hide details when scrolling down beyond 100px
      if (scrollOffset > 100 && showDetails) {
        setShowDetails(false);
      }
      // Show details when scrolling back up within 100px from the top
      else if (scrollOffset <= 100 && !showDetails) {
        setShowDetails(true);
      }
    }
  };

  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo(0);
      setShowDetails(true);
    }
  };

  if (
    (canReadConversation && !conversation) ||
    (!canReadConversation && !transcript)
  ) {
    return <div>Conversation not found</div>;
  }

  const handleToggleView = () => {
    setShowRealData((prev) => !prev);
  };

  // Dynamic item heights
  const getItemSize = (index: number) => {
    const item = listItems[index];
    if (itemHeights.current[index]) {
      return itemHeights.current[index];
    } else {
      // Provide estimated heights based on item type
      if (item.type === "date") {
        return 20; // Estimated height for date/session headers
      } else if (item.type === "session") {
        return 50;
      } else if (item.type === "message") {
        return 125; // Estimated height for message bubbles
      } else {
        return 100; // Default height
      }
    }
  };

  const ListItemRenderer: React.FC<ListChildComponentProps<ItemData>> = ({
    index,
    style,
    data,
  }) => {
    const {
      listItems,
      getCommentsForMessage,
      conversationId,
      showRealData,
      canReadConversation,
      itemHeights,
      listRef,
      highlightedItemId,
    } = data;
    const item = listItems[index];
    const itemRef = useRef<HTMLDivElement>(null);

    if (item.type === "date") {
      const isHighlighted = highlightedItemId === item.date;

      return (
        <div
          style={{
            ...style,
            overflow: "visible",
            backgroundColor: isHighlighted ? "#ffffcc" : "inherit",
          }}
          ref={itemRef}
          id={item.date}
        >
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom textAlign="center">
              {item.date}
            </Typography>
          </Box>
        </div>
      );
    } else if (item.type === "session") {
      return (
        <div style={{ ...style, overflow: "visible" }} ref={itemRef}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ color: "gray" }}>
              New Session: {item.sessionId}
            </Typography>
          </Box>
        </div>
      );
    } else if (item.type === "message") {
      const { message, targetId } = item;
      const messageComments = getCommentsForMessage(targetId);
      return (
        <div
          style={{
            ...style,
            overflow: "visible",
            width: "98%",
          }}
          ref={itemRef}
          id={targetId}
        >
          <MessageBubble
            message={message}
            conversationId={conversationId}
            isRightAligned={message.type !== "human"}
            canViewAllData={showRealData && canReadConversation}
            comments={messageComments}
            chatSessionId={message.chat_session_id}
            onHeightChange={(height) => {
              if (itemHeights.current[index] !== height) {
                itemHeights.current[index] = height;
                if (listRef.current) {
                  listRef.current.resetAfterIndex(index);
                }
              }
            }}
            isHighlighted={highlightedItemId === targetId}
          />
        </div>
      );
    }
    return null;
  };

  const renderDetails = () => {
    if (!canReadConversation || !showRealData) {
      return (
        <>
          <Typography variant="subtitle1">
            <strong>Human Initial:</strong>{" "}
            {transcript?.human_initial || conversation?.human?.full_name?.[0]}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Bot Name:</strong>{" "}
            {transcript?.bot_name || conversation?.bot?.name}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Conversation ID:</strong>{" "}
            {transcript?.conversation_id || conversation?.id}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Created At:</strong>{" "}
            {(transcript || conversation) &&
              format(
                new Date(
                  transcript?.created_at || conversation?.created_at || ""
                ),
                "yyyy-MM-dd HH:mm:ss"
              )}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Environment:</strong>{" "}
            {transcript?.environment || conversation?.environment}
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="subtitle1">
          <strong>Human:</strong> {conversation?.human.first_name} (ID:{" "}
          {conversation?.human.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Bot:</strong> {conversation?.bot.name} (ID:{" "}
          {conversation?.bot.id})
        </Typography>
        <Typography variant="subtitle1">
          <strong>Conversation ID:</strong> {conversation?.id}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Created At:</strong>{" "}
          {format(
            new Date(conversation?.created_at || ""),
            "yyyy-MM-dd HH:mm:ss"
          )}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Private:</strong> {conversation?.is_private ? "Yes" : "No"}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Environment:</strong> {conversation?.environment}
        </Typography>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {/* Messages Container */}
      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
          padding: "20px",
          minWidth: "70%",
          transition: "filter 0.3s",
          position: "relative",
        }}
      >
        <ToastContainer />
        <Collapse in={showDetails} timeout={2000} unmountOnExit>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              Conversation Details
            </Typography>
            {renderDetails()}

            {commenters.length > 0 && (
              <Box>
                <Typography variant="subtitle1">
                  <strong>Annotators:</strong>
                </Typography>
                <Box mt={1} display="flex" flexWrap="wrap">
                  {commenters.map((user) => (
                    <Box
                      key={user.id}
                      sx={{
                        display: "inline-block",
                        padding: "4px 8px",
                        margin: "4px",
                        backgroundColor: hiddenCommenters.has(user.id)
                          ? "grey"
                          : "primary.main",
                        color: "white",
                        borderRadius: "16px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // Toggle hidden commenters
                        setHiddenCommenters((prev) => {
                          const newSet = new Set(prev);
                          if (newSet.has(user.id)) {
                            newSet.delete(user.id);
                          } else {
                            newSet.add(user.id);
                          }
                          return newSet;
                        });
                      }}
                    >
                      {user.full_name}
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            <Box mt={4} display="flex" alignItems="center">
              {conversation?.owning_user_id && canViewUser && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() =>
                    handleViewUser(conversation.owning_user_id ?? "")
                  }
                >
                  View Conversation User
                </Button>
              )}

              {canReadConversation && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => handleToggleView()}
                  sx={{ marginLeft: conversation?.owning_user_id ? 2 : 0 }}
                >
                  {showRealData ? "View Anonymized Data" : "View Real Data"}
                </Button>
              )}
              <AddOverallFeedback conversationId={conversationId || ""} />
              <Button
                variant="contained"
                color="error"
                onClick={() => handleShadowBan(!conversation?.shadow_banned)}
                sx={{ marginLeft: 2 }}
              >
                {conversation?.shadow_banned ? "Lift ShadowBan" : "ShadowBan"}
              </Button>
            </Box>
          </Box>
          <ViewOverallFeedback
            conversationId={conversationId || ""}
            comments={getCommentsForMessage(`conv:${conversationId}`)}
          />{" "}
        </Collapse>

        {/* Render Messages */}
        {loading ||
        loadingTranscript ||
        loadingConversation ||
        loadingComments ? (
          <Box mt={4} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" gutterBottom>
              Chat Messages
            </Typography>{" "}
            <WindowList
              ref={listRef}
              height={window.innerHeight - 200} // Adjust based on your layout
              itemCount={listItems.length}
              itemSize={getItemSize}
              width="100%"
              onScroll={handleScroll}
              overscanCount={10}
              itemData={{
                listItems,
                getCommentsForMessage,
                conversationId,
                showRealData,
                canReadConversation,
                itemHeights,
                listRef,
                highlightedItemId,
              }}
            >
              {ListItemRenderer}
            </WindowList>
          </>
        )}

        {showScrollToTop && (
          <Fab
            color="primary"
            size="small"
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 1000,
            }}
          >
            <ArrowUpwardIcon />
          </Fab>
        )}
      </Box>

      {/* Date Selector Box */}
      {!conversation?.is_private && (
        <Box
          sx={{
            width: "20%",
            borderLeft: "1px solid #ccc",
            backgroundColor: "#f0f0f0",
            padding: "20px",
            minWidth: "200px",
            maxHeight: "100vh",
            overflowY: "auto",
            borderRadius: "16px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Select a Date
          </Typography>
          <List>
            {dates.map((date, index) => (
              <ListItem
                button
                key={index}
                selected={selectedDate === date}
                onClick={() => scrollToDate(date)}
              >
                <ListItemText primary={date} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default ConversationView;
