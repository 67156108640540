import React, { useEffect, useState, useCallback, useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { fetchUsers } from "../../redux/actions/userActions";
import { getFullNameFromUserName } from "../../utils/render";
import { RootState, AppDispatch } from "../../redux/store";
import { useStytchUser } from "@stytch/react";
import Fuse from "fuse.js";
import { debounce } from "lodash";
import { format } from "date-fns";

const UsersTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useStytchUser();
  const {
    users: rows,
    loading,
    error,
  } = useSelector((state: RootState) => state.users);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Filter states
  const [emailVerifiedFilter, setEmailVerifiedFilter] = useState<
    boolean | null
  >(null);
  const [phoneVerifiedFilter, setPhoneVerifiedFilter] = useState<
    boolean | null
  >(null);

  const [environmentFilter, setEnvironmentFilter] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");

  const removeUserParams = useCallback(() => {
    if (searchParams.has("user")) {
      searchParams.delete("user");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const debouncedFetchUsers = useMemo(
    () => debounce(() => dispatch(fetchUsers()), 300),
    [dispatch]
  );

  useEffect(() => {
    if (user) {
      debouncedFetchUsers();
    }
    // Clean up the debounced function on unmount
    return () => {
      debouncedFetchUsers.cancel();
    };
  }, [debouncedFetchUsers, user]);

  const filteredRows = useMemo(() => {
    let data = rows;

    // Apply filters
    if (emailVerifiedFilter !== null) {
      data = data.filter((item) => item.email_verified === emailVerifiedFilter);
    }

    if (phoneVerifiedFilter !== null) {
      data = data.filter(
        (item) => item.phone_number_verified === phoneVerifiedFilter
      );
    }

    if (environmentFilter) {
      data = data.filter((item) => item.environment === environmentFilter);
    }

    if (statusFilter) {
      data = data.filter((item) => item.status === statusFilter);
    }

    // Apply search query
    if (searchQuery) {
      const fuse = new Fuse(data, {
        keys: [
          "name.first_name",
          "name.last_name",
          "id",
          "email",
          "environment",
        ],
        threshold: 0.3,
      });

      data = fuse.search(searchQuery).map((result) => result.item);
    }

    return data;
  }, [
    searchQuery,
    rows,
    emailVerifiedFilter,
    phoneVerifiedFilter,
    environmentFilter,
    statusFilter,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("user");

    if (userId && filteredRows.length > 0) {
      removeUserParams();
      navigate(`/dashboard/users/${userId}`);
    }
  }, [filteredRows, location.search, navigate, removeUserParams]);

  const handleViewUser = useCallback((id: string) => {
    window.open(`/dashboard/users/${id}`, "_blank");
  }, []);

  const renderBooleanCell = (params: any) => (
    <p style={{ textAlign: "center", margin: 0 }}>
      {params.value ? "✔️" : "❌"}
    </p>
  );

  const renderNameCell = (params: any) => (
    <p style={{ textAlign: "center", margin: 0 }}>
      {getFullNameFromUserName(params.value)}
    </p>
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "name",
        headerName: "User Name",
        flex: 1,
        minWidth: 100,
        renderCell: renderNameCell,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "id",
        headerName: "User ID",
        flex: 2,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "created_at",
        headerName: "Created At",
        flex: 1,
        minWidth: 150,
        headerAlign: "center",
        align: "center",
        valueGetter: (params: any) => {
          return params
            ? format(new Date(params), "yyyy-MM-dd HH:mm:ss")
            : null;
        },
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "is_admin",
        headerName: "Is Admin?",
        flex: 0.5,
        minWidth: 70,
        renderCell: renderBooleanCell,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "phone_number_verified",
        headerName: "# Verified?",
        flex: 0.5,
        minWidth: 70,
        renderCell: renderBooleanCell,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "email_verified",
        headerName: "@ Verified?",
        flex: 0.5,
        minWidth: 70,
        renderCell: renderBooleanCell,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "status",
        headerName: "Status",
        flex: 0.5,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "environment",
        headerName: "Environment",
        flex: 0.5,
        minWidth: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "actions",
        headerName: "",
        flex: 0.5,
        minWidth: 150,
        renderCell: (params: any) => (
          <p style={{ textAlign: "center", margin: 0 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewUser(params.row.id)}
            >
              View
            </Button>
          </p>
        ),
        headerAlign: "center",
      },
    ],
    [handleViewUser]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          mt: 2,
          width: "100%",
        }}
      >
        {/* Search Query */}
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ flex: 1 }}
        />

        {/* Email Exists Filter */}

        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            label="Status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="waitlisted">Waitlisted</MenuItem>
            <MenuItem value="suspended">Suspended</MenuItem>
            <MenuItem value="banned">Banned</MenuItem>
            <MenuItem value="onboarding">Onboarding</MenuItem>
          </Select>
        </FormControl>

        {/* Environment Filter */}
        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>Environment</InputLabel>
          <Select
            value={environmentFilter}
            onChange={(e) => setEnvironmentFilter(e.target.value)}
            label="Environment"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="qa">QA</MenuItem>
            <MenuItem value="staging">Staging</MenuItem>
            <MenuItem value="production">Production</MenuItem>
            {/* Add other environments as needed */}
          </Select>
        </FormControl>
        {/* Email Verified Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={emailVerifiedFilter === true}
              onChange={(e) =>
                setEmailVerifiedFilter(e.target.checked ? true : null)
              }
            />
          }
          label="@ Verified"
        />

        {/* Phone Verified Filter */}
        <FormControlLabel
          control={
            <Checkbox
              checked={phoneVerifiedFilter === true}
              onChange={(e) =>
                setPhoneVerifiedFilter(e.target.checked ? true : null)
              }
            />
          }
          label="# Verified"
        />
        <Button
          variant="outlined"
          onClick={() => {
            setSearchQuery("");
            setEmailVerifiedFilter(null);
            setPhoneVerifiedFilter(null);
            setEnvironmentFilter("");
            setStatusFilter(""); // Reset status filter
          }}
        >
          Reset Filters
        </Button>
      </Box>

      <Box sx={{ height: "65vh", width: "100%", mt: 2 }}>
        <DataGrid rows={filteredRows} columns={columns} />
      </Box>
    </>
  );
};

export default UsersTab;
