import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { addComment } from "../../../../redux/actions/conversationsActions";

interface AddOverallFeedbackProps {
  conversationId: string;
}

const AddOverallFeedback: React.FC<AddOverallFeedbackProps> = ({
  conversationId,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentText, setCommentText] = useState("");

  const handleAddComment = () => {
    if (commentText.trim() !== "") {
      dispatch(
        addComment({
          conversationId: conversationId,
          targetId: `conv:${conversationId}`,
          comment: commentText,
        })
      );
      setCommentText("");
      setShowCommentModal(false);
    }
  };

  return (
    <>
      {/* Button to open comment modal */}
      <Button
        variant="contained"
        startIcon={<AddCommentIcon />}
        onClick={() => setShowCommentModal(true)}
        sx={{ marginLeft: 2 }}
      >
        Add Feedback
      </Button>

      {/* Modal to add a new comment */}
      <Modal open={showCommentModal} onClose={() => setShowCommentModal(false)}>
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" component="h2">
              Add Comment
            </Typography>
            <IconButton
              style={{ marginTop: "-4px" }}
              onClick={() => setShowCommentModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <Button variant="contained" onClick={handleAddComment} sx={{ mt: 2 }}>
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AddOverallFeedback;
