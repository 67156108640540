import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { StytchProvider } from "@stytch/react";
import { StytchUIClient } from "@stytch/vanilla-js";
import TokenAuthenticator from "./auth/tokenAuthenticator";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store"; // Import persistor
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { BrowserRouter } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const stytch = new StytchUIClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "test key"
);

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    {/* StytchProvider gives our app access to the Stytch client */}
    <StytchProvider stytch={stytch}>
      <TokenAuthenticator>
        <Provider store={store}>
          <PersistGate loading={<CircularProgress />} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </TokenAuthenticator>
    </StytchProvider>
  </React.StrictMode>
);
